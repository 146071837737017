import { string, addMethod, StringSchema, DateValidationMessage } from 'yup';

import { getFormattedDate } from '@itm/shared-frontend/lib/components';
import { hexColorRegExp, getNextDayISO, getPrevDayISO } from '@itm/shared-frontend/lib/utils';

import { apiAddressRegExp, phoneRegex, strongPasswordRegExp } from 'src/utils/constants';

addMethod<StringSchema>(string, 'password', function () {
  return this.label('Password')
    .trim()
    .required()
    .matches(
      strongPasswordRegExp,
      ({ label }) =>
        `${label} should contain at least an uppercase letter, lowercase letter, number and special character`,
    )
    .min(12);
});

addMethod<StringSchema>(string, 'phone', function () {
  return this.matches(phoneRegex, {
    message: 'Enter the phone number in international format with a space after the country code, like +44 7411592883',
    excludeEmptyString: true,
  });
});

addMethod<StringSchema>(string, 'hexColor', function () {
  return this.matches(hexColorRegExp, ({ label }) => `${label} must be valid color, like: #012345`);
});

addMethod<StringSchema>(string, 'apiAddress', function () {
  return this.matches(apiAddressRegExp, ({ label }) => `${label} must be a valid in IPv4 or IPv6 format`);
});

addMethod<StringSchema>(string, 'emptyToUndefined', function () {
  return this.transform((value) => (value && value.length ? value : undefined));
});

addMethod<StringSchema>(string, 'dateFrom', function (dateToKey: string = 'dateTo', message?: DateValidationMessage) {
  return this.when(dateToKey, ([dateTo]: (string | undefined)[], scheme) =>
    dateTo
      ? scheme.test(
          'dateFromTest',
          (params) =>
            message
              ? typeof message === 'string'
                ? message
                : message(params, dateTo)
              : `${params.label} field must be earlier than ${getFormattedDate(getNextDayISO(dateTo))}`,
          (dateFrom) => (dateFrom ? new Date(dateFrom) < new Date(getNextDayISO(dateTo)) : true),
        )
      : scheme,
  );
});

addMethod<StringSchema>(string, 'dateTo', function (dateFromKey: string = 'dateFrom', message?: DateValidationMessage) {
  return this.when(dateFromKey, ([dateFrom]: (string | undefined)[], scheme) =>
    dateFrom
      ? scheme.test(
          'dateToTest',
          (params) =>
            message
              ? typeof message === 'string'
                ? message
                : message(params, dateFrom)
              : `${params.label} field must be later than ${getFormattedDate(getPrevDayISO(dateFrom))}`,
          (dateTo) => (dateTo ? new Date(getNextDayISO(dateTo)) > new Date(dateFrom) : true),
        )
      : scheme,
  );
});
